import { ButtonLink, InternalLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import { Heading2, ParagraphIntro } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'

import { Infopanel } from 'src/config/generated/sanitySchema'
import { aboutUsPath, dashboardPath } from 'src/config/paths'

type FocusSectionProps = {
  cmsData: Infopanel | undefined
  skipContainer?: boolean
}

export const FocusSection = ({
  cmsData,
  skipContainer = false
}: FocusSectionProps) => {
  if (!cmsData) return null

  return (
    <FullWidthContainer
      skipContainer={skipContainer}
      backgroundColor={FullWidthContainerBackgroundColor.aquaSpring}
      id='section-benefits'
      className='lg:py-[100px]'
    >
      <div className='flex flex-col lg:flex-row lg:gap-10 gap-5'>
        <div className='relative flex-1 min-h-[358px] xl:min-h-[506px]'>
          <SanityImage
            alt='Focus section image'
            source={cmsData.image}
            fill
            className='object-cover rounded-[10px]'
          />
        </div>
        <div className='flex-1 flex flex-col'>
          <Heading2>{cmsData.title}</Heading2>
          <ParagraphIntro>{cmsData.subtitle}</ParagraphIntro>
          <div className='flex flex-col gap-3 items-start'>
            <ul className='list-check focus'>
              <li>
                <span>Make a tangible impact, whatever your budget.</span>
              </li>
              <li>
                <span>Support a national spread of planting projects.</span>
              </li>
              <li>
                <span>
                  Access your unique activity{' '}
                  <InternalLink color='black-underlined' href={dashboardPath()}>
                    dashboard
                  </InternalLink>{' '}
                  and more.
                </span>
              </li>
              <li>
                <span>
                  Join the trailblazing restoration community,{' '}
                  <InternalLink color='black-underlined' href={aboutUsPath}>
                    growing since 1990.
                  </InternalLink>
                </span>
              </li>
            </ul>
            {cmsData.ctas?.map(cta => {
              const isExternal = !!cta.link
              return (
                <ButtonLink
                  key={cta._key}
                  variant={cta.variant}
                  color={cta.color}
                  isExternal={isExternal}
                  href={isExternal ? cta.link! : cta.route!}
                  size={cta.variant !== 'text' ? 'large' : 'base'}
                >
                  {cta.title}
                </ButtonLink>
              )
            })}
          </div>
        </div>
      </div>
    </FullWidthContainer>
  )
}
