import { IoLocation } from 'react-icons/io5'

import { Image } from 'src/components/atoms/Image'
import { Heading4, ParagraphNoSpace } from 'src/components/atoms/Typography'

import { ImageDetailTile as ImageDetailTileType } from 'src/config/generated/sanitySchema'
import { camelToFlat } from 'src/utils/common'
import { getSanityImageUrl } from 'src/utils/sanity'

type ImageDetailTileProps = Omit<ImageDetailTileType, 'image' | '_type'> & {
  image: ImageDetailTileType['image'] | string
}

const ImageDetailTile = ({
  image,
  tileName,
  description,
  labelText,
  labelValue,
  location,
  tags
}: ImageDetailTileProps) => {
  const imageSrc = typeof image === 'string' ? image : getSanityImageUrl(image)
  return (
    <div className='h-full flex flex-col bg-mostlyGreen rounded-[10px] overflow-clip'>
      <div className='relative w-full h-80 sm:h-[380px] md:h-60 lg:h-[410px]'>
        <Image
          alt={tileName}
          src={imageSrc}
          fill
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='flex-1 flex flex-col gap-[10px] p-5 md:p-[30px] text-white'>
        <div className='flex flex-col flex-1 gap-[10px]'>
          <Heading4 className='lg:!text-[20px] !font-bold !m-0'>
            {tileName}
          </Heading4>
          {description ? (
            <ParagraphNoSpace className='text-[20px] font-medium inline'>
              {description}
            </ParagraphNoSpace>
          ) : null}
          {location ? (
            <div>
              <IoLocation className='w-6 h-6 -translate-y-1 -translate-x-1 inline' />
              <ParagraphNoSpace className='text-[20px] font-medium inline'>
                {location}
              </ParagraphNoSpace>
            </div>
          ) : null}
          <ParagraphNoSpace>
            <span className='font-bold text-[18px] md:text-[20px]'>
              {labelValue}
            </span>{' '}
            {labelText}
          </ParagraphNoSpace>
        </div>
        {tags?.length ? (
          <div className='flex flex-row flex-wrap gap-[5px]'>
            {tags.map(p => (
              <div
                key={`purpose-${p}`}
                className='bg-[#f5f5f5] text-mostlyGreen py-1 px-2 text-[13px] font-semibold'
              >
                {camelToFlat(p)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ImageDetailTile
