import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import pluralize from 'pluralize'
import { MouseEventHandler, useMemo } from 'react'
import { FaTimes } from 'react-icons/fa'

import {
  AddPlants,
  BulkGift,
  CrowdFund,
  Gift,
  Monthly,
  OneOff
} from 'src/components/atoms/Icons'
import { InternalLink } from 'src/components/atoms/Link'

import { GetLatestUserEventQuery } from 'src/config/generated/graphql'
import { allActivitiesPath } from 'src/config/paths'
import { ArrayElement } from 'src/types/common'
import { PurchaseType } from 'src/types/purchase'
import { UserEventType } from 'src/types/user'
import { numberInWords } from 'src/utils/common'

dayjs.extend(relativeTime)
dayjs.extend(utc)

type ActivityActionType =
  | 'planted'
  | 'oneOff'
  | 'monthly'
  | 'gift'
  | 'bulkGift'
  | 'crowdFund'

const getActivityTitle = (quantity: number, activityAction: string) =>
  `${numberInWords(quantity)} ${pluralize('tree', quantity)} ${activityAction}`

const activityDetailMap: {
  [key in ActivityActionType]: {
    title: string | ((quantity: number) => string)
    icon: JSX.Element
    actionType?: string
  }
} = {
  planted: {
    icon: <AddPlants className='text-mostlyGreen w-10 h-10' />,
    title: quantity => getActivityTitle(quantity, 'planted')
  },
  oneOff: {
    icon: <OneOff className='text-mostlyGreen w-10 h-10' />,
    title: quantity => getActivityTitle(quantity, 'donated')
  },
  monthly: {
    icon: <Monthly className='text-mostlyGreen w-10 h-10' />,
    title: quantity => getActivityTitle(quantity, 'donated'),
    actionType: 'Monthly'
  },
  gift: {
    icon: <Gift className='text-mostlyGreen w-10 h-10 -translate-x-1' />,
    title: quantity => getActivityTitle(quantity, 'gifted'),
    actionType: 'Gift'
  },
  bulkGift: {
    icon: (
      <BulkGift className='text-mostlyGreen w-10 h-10 translate-x-0.5 -translate-y-0.5' />
    ),
    title: quantity => getActivityTitle(quantity, 'gifted'),
    actionType: 'Bulk gift'
  },
  crowdFund: {
    icon: (
      <CrowdFund className='text-mostlyGreen w-10 h-10 -translate-x-0.5 -translate-y-0.5' />
    ),
    title: quantity => getActivityTitle(quantity, 'donated'),
    actionType: 'Tree registry'
  }
}

export type PurchaseNotificationProps = {
  notification: ArrayElement<GetLatestUserEventQuery['UserEventView']>
  onCloseClick: () => void
}

export const PurchaseNotification = ({
  notification,
  onCloseClick
}: PurchaseNotificationProps) => {
  const handleCloseClick: MouseEventHandler<SVGElement> = e => {
    e.preventDefault()
    onCloseClick()
  }

  const activityDetail = useMemo(() => {
    switch (true) {
      case notification.Type === UserEventType.Planted:
        return activityDetailMap.planted
      case notification.PurchaseType === PurchaseType.FundedTree &&
        notification.HasSubscriptionId:
        return activityDetailMap.monthly
      case notification.PurchaseType === PurchaseType.FundedTree:
        return activityDetailMap.oneOff
      case notification.PurchaseType === PurchaseType.GiftedTree:
        return activityDetailMap.gift
      case notification.PurchaseType === PurchaseType.BulkGifted:
        return activityDetailMap.bulkGift
      case notification.PurchaseType === PurchaseType.RegistryGift:
        return activityDetailMap.crowdFund
      default:
        return null
    }
  }, [notification])

  if (!activityDetail) return null

  return (
    <div className='absolute bottom-0 flex flex-row justify-center w-full'>
      <div className='max-w-[1148px] w-full flex-1 mx-5 md:mx-[110px]'>
        <div className='text-white w-full  flex flex-row justify-center md:justify-end'>
          <InternalLink href={allActivitiesPath}>
            <div className='rounded-t-[5px] max-w-[315px] flex flex-row text-white bg-mostlyGreen hover:bg-darkGreen hover:shadow-[0px_3px_15px_0px_rgba(0,_0,_0,_0.25)] p-4 md:p-5 gap-[10px] items-center '>
              <div className='bg-white flex-shrink-0 rounded-full w-12 h-12 md:w-[60px] md:h-[60px] md:pt-[10px] pl-2 pt-1 md:pl-[14px]'>
                {activityDetail.icon}
              </div>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <span className='text-16px font-bold'>
                  {typeof activityDetail.title === 'function'
                    ? activityDetail.title(notification.Quantity || 0)
                    : activityDetail.title}
                </span>
                {notification.Comment ? (
                  <span className='italic truncate text-ellipsis'>{`"${notification.Comment}"`}</span>
                ) : null}
                <span className='text-16px font-medium'>
                  {activityDetail.actionType
                    ? `${activityDetail.actionType} - `
                    : null}{' '}
                  {dayjs().to(dayjs.utc(notification.CreatedOn))}
                </span>
                <span className='text-white text-opacity-80'>
                  more actions &rsaquo;
                </span>
              </div>
              <div className='self-start'>
                <FaTimes
                  className='cursor-pointer'
                  onClick={handleCloseClick}
                />
              </div>
            </div>
          </InternalLink>
        </div>
      </div>
    </div>
  )
}
