import dayjs from 'dayjs'

import { InternalLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import { ParagraphSmaller, SpanDisplay } from 'src/components/atoms/Typography'

import { viewBlogPostPath } from 'src/config/paths'
import { PostsQueryPost } from 'src/server/router/sanity/groq/post/types'

export interface BlogPostCardProps {
  post: PostsQueryPost
  className?: string
}

export const BlogPostCard = ({ post, className }: BlogPostCardProps) => {
  return (
    <div
      className={`${className} rounded-[10px] flex flex-col h-auto hover:shadow-lg transition-shadow duration-300 overflow-hidden`}
    >
      <InternalLink
        color='black'
        className='transition-colors duration-300'
        key={post.slug.current}
        href={viewBlogPostPath({ routeParams: { slug: post.slug.current } })}
      >
        <div className='relative w-full h-[403px]'>
          <SanityImage
            className='object-cover object-center'
            alt={post.slug.current}
            source={post.mainImage}
            fill
            // sourceWidth={300}
            // sourceHeight={300}
            // width={380}
            // height={403}
          />
        </div>
        <div className='p-5 font-bold'>
          <ParagraphSmaller className='uppercase text-shark'>
            {dayjs(post.publishedAt).format('DD MMM YYYY')}
          </ParagraphSmaller>
          <SpanDisplay>{post.title}</SpanDisplay>
        </div>
      </InternalLink>
    </div>
  )
}
