import { ButtonLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import { Heading2, ParagraphIntro } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'

import { Infopanel } from 'src/config/generated/sanitySchema'

type AudienceSectionProps = {
  cmsData: Infopanel | undefined
  skipContainer?: boolean
}

export const AudienceSection = ({
  cmsData,
  skipContainer = false
}: AudienceSectionProps) => {
  if (!cmsData) return null

  return (
    <FullWidthContainer
      skipContainer={skipContainer}
      backgroundColor={FullWidthContainerBackgroundColor.white}
      id='section-planter'
      className='md:pb-[0px]'
    >
      <div className='flex flex-col gap-4 md:gap-[50px]'>
        <div className='bg-mostlyGreen flex flex-col lg:flex-row lg:gap-10 gap-5 py-[30px] px-5 md:p-[60px] rounded-[10px]'>
          <div className='relative flex-1 min-h-[300px] xl:min-h-[540px]'>
            <SanityImage
              alt='AudienceSection'
              source={cmsData.image}
              fill
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className='flex-1 flex flex-col text-white lg:justify-center'>
            <Heading2>{cmsData.title}</Heading2>
            <ParagraphIntro className='text-white'>
              {cmsData.subtitle}
            </ParagraphIntro>
            <div className='flex flex-col gap-3 items-start'>
              {cmsData.ctas?.map(cta => {
                const isExternal = !!cta.link
                return (
                  <ButtonLink
                    size={cta.variant !== 'text' ? 'large' : 'base'}
                    key={cta._key}
                    variant={cta.variant}
                    color={cta.color}
                    isExternal={isExternal}
                    href={isExternal ? cta.link! : cta.route!}
                  >
                    {cta.title}
                  </ButtonLink>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </FullWidthContainer>
  )
}
