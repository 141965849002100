import { useCallback, useMemo } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { SanityImage } from 'src/components/atoms/SanityImage'
import {
  Heading2,
  Heading3,
  Paragraph,
  ParagraphIntro
} from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'
import {
  NewsletterSignupForm,
  NewsletterSignupFormProps
} from 'src/components/organisms/forms/NewsletterSignupForm'

import { Infopanel } from 'src/config/generated/sanitySchema'
import { inferMutationInput, trpc } from 'src/utils/trpc'

interface Props {
  Form?: (props: NewsletterSignupFormProps) => JSX.Element
  cmsData: Infopanel
  skipContainer?: boolean
}

export const NewsletterSignup = ({
  Form = NewsletterSignupForm,
  cmsData,
  skipContainer = false
}: Props) => {
  const { mutate, isLoading, isSuccess, error } = trpc.useMutation([
    'emails.newsletterSignup'
  ])
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(
    async (
      values: Omit<inferMutationInput<'emails.newsletterSignup'>, 'token'>
    ) => {
      if (!executeRecaptcha) {
        // Execute recaptcha not yet available
        return
      }

      const token = await executeRecaptcha('newsletter_signup')
      mutate({ ...values, token })
    },
    [executeRecaptcha, mutate]
  )

  const content = useMemo(() => {
    if (error)
      return (
        <>
          <Heading2>Something went wrong!</Heading2>
          <Paragraph>{error.message}</Paragraph>
        </>
      )
    if (isSuccess)
      return (
        <>
          <Heading3 className='font-semibold text-[28px]'>Thanks!</Heading3>
          <Paragraph>
            We should have landed in your inbox. Just confirm your email to
            complete the process.
          </Paragraph>
        </>
      )
    if (!cmsData) return null
    return (
      <>
        <Heading2>{cmsData.title}</Heading2>
        <ParagraphIntro>{cmsData.subtitle}</ParagraphIntro>
        <Form onSubmit={handleReCaptchaVerify} isLoading={isLoading} />
      </>
    )
  }, [Form, isSuccess, cmsData, error, handleReCaptchaVerify, isLoading])

  if (!cmsData) return null

  return (
    <FullWidthContainer
      skipContainer={skipContainer}
      backgroundColor={FullWidthContainerBackgroundColor.aquaSpring}
      verticalPadding='none'
    >
      <div className='flex lg:flex-row flex-col lg:min-h-[588px] overflow-clip rounded-t-[10px]'>
        <div className='bg-white flex-1 p-6 lg:p-10 xl:px-20 lg:py-14 py-6 flex flex-col justify-center'>
          {content}
        </div>
        <div className='bg-white flex-1 pr-10 pb-0 flex-col justify-end hidden lg:flex'>
          <SanityImage
            alt='Newsletter'
            source={cmsData.image}
            layout='responsive'
            objectFit='contain'
            width={200}
            height={200}
          />
        </div>
      </div>
    </FullWidthContainer>
  )
}
